import React from 'react';
import styled from 'styled-components';

interface Props {
  isLoading?: boolean;
  className?: string;
  value?: string;
  id?: string;
  placeholder?: string;
  rows?: number;
  onChange?: any;
  maxLength?: number;
  label: string;
}

const StyledLabel = styled.span`
  color: rgba(255, 255, 255, 0.6);
  font-size: 11px;
`;

const StyledInput = styled.textarea`
  color: rgba(255, 255, 255, 0.8);
  font-size: 14px;
  border: 0.5px solid rgba(255, 255, 255, 0.5);
  border-radius: 6px;
  background: #27292D;
  transition: all 0.2s;
  resize: none;
  
  &:focus {
    outline: none;
    border: 0.5px solid rgba(255, 255, 255, 0.7);
  }
  
  ::placeholder {
      color: rgba(255, 255, 255, 0.5);
  }
`;

export default function TextField({id, isLoading, value, placeholder, label, className, rows = 3, onChange, maxLength}: Props) {
  return (
    <div className={className}>
      <StyledLabel className="uppercase">{label}</StyledLabel>
      <StyledInput id={id} className="w-full py-1 px-3" onChange={onChange} value={value} maxLength={maxLength} placeholder={placeholder} rows={3} disabled={isLoading} />
    </div>
  )
}
