/**
 *
 * EventsSidebar
 *
 */

import React from "react";
import styled from "styled-components/macro";

import {colors} from '../../../utils/colors';
import EventsIcon from './EventsIcon';
import {Link} from 'react-router-dom';

interface Props {}

export function Sidebar(props: Props) {
  return (
    <>
      <div className="h-screen w-24 overflow-auto flex-shrink-0" style={{background: colors.modalBackground}}>
        <Link to="/users">
          <EventsIcon name={"Users"}>
            <svg fill="currentColor" className="m-auto" width={40} viewBox="0 0 20 20"><path fillRule="evenodd" d="M10 9a3 3 0 100-6 3 3 0 000 6zm-7 9a7 7 0 1114 0H3z" clipRule="evenodd"/></svg>
          </EventsIcon>
        </Link>

        <Link to="/settings">
          <EventsIcon name={"Teams"}>
            <svg fill="currentColor" className="m-auto" width={40} viewBox="0 0 20 20"><path d="M9 6a3 3 0 11-6 0 3 3 0 016 0zM17 6a3 3 0 11-6 0 3 3 0 016 0zM12.93 17c.046-.327.07-.66.07-1a6.97 6.97 0 00-1.5-4.33A5 5 0 0119 16v1h-6.07zM6 11a5 5 0 015 5v1H1v-1a5 5 0 015-5z"/></svg>
          </EventsIcon>
        </Link>

        <Link to="/requests">
          <EventsIcon name={"Team Requests"}>
            <svg fill="currentColor" className="m-auto" width={40} viewBox="0 0 20 20"><path d="M2 6a2 2 0 012-2h12a2 2 0 012 2v2a2 2 0 100 4v2a2 2 0 01-2 2H4a2 2 0 01-2-2v-2a2 2 0 100-4V6z"/></svg>
          </EventsIcon>
        </Link>

        <Link to="/settings">
          <EventsIcon name={"Disputes"}>
            <svg fill="currentColor" className="m-auto" width={40} viewBox="0 0 20 20"><path fillRule="evenodd" d="M3 6a3 3 0 013-3h10a1 1 0 01.8 1.6L14.25 8l2.55 3.4A1 1 0 0116 13H6a1 1 0 00-1 1v3a1 1 0 11-2 0V6z" clipRule="evenodd"/></svg>
          </EventsIcon>
        </Link>
      </div>
    </>
  );
}

const Div = styled.div``;
