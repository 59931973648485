import {User} from '../../../types/User';
import React from 'react';

interface Props {
  user: User;
}

export default function Teams({user}: Props) {
  return (
    <div className="px-12 py-4 mx-auto mt-12 content-modal" style={{width: 475}}>
      <div className="" style={{color: 'rgba(255, 255, 255, 0.8)'}}>Teams</div>
      {user.teams.map(team => (
        <div className="p-4 flex w-full rounded-lg my-2 cursor-pointer items-center justify-center" style={{backgroundColor: '#25262A', color: 'rgba(255, 255, 255, 0.6)'}}>
          <div className="flex-grow">{team.name}</div>
          <div className="w-8 h-8">
            <svg fill="currentColor" viewBox="0 0 20 20"><path fillRule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clipRule="evenodd"/></svg>
          </div>
        </div>
      ))}
    </div>
  )
}
