import React, {useEffect, useState} from 'react';
import {useParams, useHistory} from 'react-router-dom';
import {colors} from '../../../utils/colors';
import Input from '../Input';
import Button from '../Button';
import {actions} from '../../containers/Auth/slice'
import axios from 'axios';
import {useDispatch} from 'react-redux';

export default function Login() {
  const [loginCode, setLoginCode] = useState<string>('');
  const [error, setError] = useState('')
  const {id} = useParams();
  const history = useHistory();
  const dispatch = useDispatch();

  const submitCode = () => {
    setError('')
    axios.post(`moderation-auth/verify/${loginCode}`, {},{headers: {Authorization: `Bearer ${id}`}}).then(code => {
      dispatch(actions.authEnd(code.data.jwt))
      history.replace('/')
    }).catch(() => {
      setError('Invalid code. Try again.')
    })

  }

  useEffect(() => {
    axios.post('moderation-auth/send', {},{headers: {Authorization: `Bearer ${id}`}}).catch(() => {
      setError('Unable to send login code. Try again.')
    })
  }, [])

  return (
    <div className="h-screen w-full" style={{background: colors.mainContentBackground}}>
      <div className="mx-auto mt-20 p-10 content-modal" style={{width: 300}}>
        <span style={{color: 'rgba(255, 255, 255, 0.9)', fontSize: 20}}>Enter your one-time login code</span>
        <Input className="my-4" label="Login Code" onChange={(e) => setLoginCode(e.target.value)} value={loginCode} maxLength={5} />
        <Button disabled={!(loginCode.length == 5)} onClick={submitCode} text="Verify Code" />
        <div className="mt-1" style={{color: colors.red, fontSize: 15}}>{error}</div>

      </div>
    </div>
  )
}
