import {buildTeamPreviewFromResponse, TeamPreview} from './Team';

export interface User {
  id: string;
  nickname: string
  bio: string;
  banned: string;
  warning: string;
  iconUrl: string;
  steamId: string;
  teams: TeamPreview[]
}

export interface Profile {
  id: string;
  nickname: string;
  iconUrl: string;
}

export const buildProfileFromResponse = (data): Profile => ({
  id: data.id,
  nickname: data.nickname,
  iconUrl: data.iconUrl,
})

export const buildUserFromResponse = (data): User => ({
  id: data.id,
  nickname: data.nickname,
  bio: data.bio,
  banned: data.banned,
  warning: data.warning,
  iconUrl: data.iconUrl,
  steamId: data.steamId,
  teams: data.teams.map(team => buildTeamPreviewFromResponse(team)),
})
