/**
 *
 * App
 *
 * This component is the skeleton around the actual pages, and should only
 * contain code that should be seen on all pages. (e.g. navigation bar)
 */

import * as React from "react";
import { Helmet } from "react-helmet-async";
import { Switch, Route } from "react-router-dom";

import { GlobalStyle } from "styles/global-styles";

import { HomePage } from "./containers/HomePage/Loadable";
import { NotFoundPage } from "./components/NotFoundPage/Loadable";
import {PrivateRoute} from './routes';
import {Auth} from './containers/Auth';
import {useSelector} from 'react-redux';
import {selectIsAuthenticated} from './containers/Auth/selectors';
import {useEffect} from 'react';
import {Sidebar} from './components/Sidebar';
import LoadingComponent from './components/LoadingComponent';
import Login from './components/Login';
import UserView from './components/User';
import UsersHome from "./components/User/Home";
import TeamSupport from "./components/TeamRequests";

export function App() {
  const isAuthed = useSelector(selectIsAuthenticated)
  useEffect(() => {console.log(isAuthed)}, [isAuthed])
  return (
    <>
      <Helmet
        defaultTitle="RustLounge World"
      >
        <meta name="description" content="RustLounge" />
      </Helmet>

      <div className="flex">
        {isAuthed && (<Route exact component={Sidebar}/>)}
        <Switch>
          <PrivateRoute exact path="/" component={HomePage} />
          <Route exact path="/auth/:id" component={Login} />
          <PrivateRoute exact path="/user/:id" component={UserView} />
          <PrivateRoute exact path="/users" component={UsersHome} />
          <PrivateRoute exact path="/requests" component={TeamSupport} />
          <Route component={LoadingComponent} />
        </Switch>
      </div>
      <GlobalStyle />
      <Auth />
    </>
  );
}
