import { RouteProps, Route } from 'react-router-dom';
import React, {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import LoadingComponent from './components/LoadingComponent';
import {useInjectReducer, useInjectSaga} from '../utils/redux-injectors';
import {reducer, sliceKey, actions} from './containers/Auth/slice';
import {authSaga} from './containers/Auth/saga';
import {selectIsAuthenticated, selectIsInitialLoadFinished} from './containers/Auth/selectors';

type Props = Omit<RouteProps, 'component'> & {
  component: React.ComponentType<any>;
};

function useInitialLoad() {
  useInjectReducer({ key: sliceKey, reducer: reducer });
  useInjectSaga({ key: sliceKey, saga: authSaga });
  const isAuthenticated = useSelector(selectIsAuthenticated);
  const initialLoadFinished = useSelector(selectIsInitialLoadFinished);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(actions.authStart());
  }, [dispatch]);
  return [initialLoadFinished, isAuthenticated];
}

export function PrivateRoute({ component: Component, ...props }: Props) {
  const [initialLoadFinished, isAuthenticated] = useInitialLoad();
  const displayComponent = childProps => {
    if (initialLoadFinished) {
      if (isAuthenticated) {
        return <Component {...childProps} />;
      } else {
        return <LoadingComponent />;
      }
    } else {
      return <LoadingComponent />
    }
  };
  return <Route {...props} render={displayComponent} />;
}
