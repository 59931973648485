import {User} from '../../../types/User';
import React, {useState} from 'react';
import {useFormik} from 'formik';
import axios from 'axios';
import {colors} from '../../../utils/colors';
import Input from '../Input';
import TextField from '../TextField';
import Button from '../Button';

interface Props {
  user: User
}

export default function Profile({user}: Props) {
  const [loading, setLoading] = useState<boolean>(false);

  const formik = useFormik({
    initialValues: {
      nickname: user.nickname,
      bio: user.bio || '',
    },
    onSubmit: async values => {
      setLoading(true);
      await axios.post('user', {nickname: values.nickname, bio: values.bio});
      setLoading(false);
    }
  })

  return (
    <>
    <div className="px-12 py-4 mx-auto mt-12 content-modal" style={{width: 475}}>
      <Input id="nickname" label="nickname" value={formik.values.nickname} onChange={formik.handleChange} placeholder="Enter a nickname" />
      <TextField className="mt-2" id="bio" label="bio" value={formik.values.bio} onChange={formik.handleChange} placeholder="Enter a public bio" />
      <div className="text-center mt-2">
        <Button isLoading={loading} onClick={formik.handleSubmit} className="mx-auto" text="Save"/>
      </div>
    </div>
  <div className="px-12 py-4 mx-auto mt-4 content-modal" style={{width: 475}}>
    <div style={{color: 'rgba(255, 255, 255, 0.6)', fontSize: 16}}>SteamID: {user.steamId}</div>
    {!!user.warning && (
      <div style={{color: colors.red, fontSize: 16}}>Warned for: {user.warning}</div>
    )}
    {!!user.banned && (
      <div style={{color: colors.red, fontSize: 16}}>Banned for: {user.banned}</div>
    )}
  </div>
      </>
  )
}
