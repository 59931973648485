import React, { useCallback, useEffect, useMemo, useState } from "react";

import axios from "axios";
import {buildTeamRequestFromResponse, TeamRequest} from "../../../types/TeamRequest";
import {colors} from "../../../utils/colors";
import moment from "moment";

interface RequestProps {
  request: TeamRequest;
  onChange: (r: string) => any;
}

function RequestBox({ request,onChange }: RequestProps) {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [content, setContent] = useState<string>("");
  const messages = useMemo(
    () =>
      request.messages
        .slice()
        .sort(
          (a, b) =>
            new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime()
        ),
    [request]
  );

  const date = useCallback(() => {
    const date = new Date(request.createdAt);
    return moment(date).format("l");
  }, [request.createdAt]);

  const addReply = async () => {
    setIsLoading(true);
    const res = await axios.put(`moderation/request/${request.id}/reply`, {
      content,
    });
    onChange(request.id)
    setContent("");
    setIsLoading(false);
  };

  const closeRequest = async () => {
    setIsLoading(true);
    const res = await axios.post(`moderation/request/${request.id}/close`);
    onChange(request.id)
  };

  if (messages.length === 0) return null;

  return (
    <div className="mt-3 content-modal" style={{ width: 550 }}>
      <div
        className="px-5 py-2 flex cursor-pointer"
        onClick={() => setIsOpen(!isOpen)}
      >
        <div className="flex flex-grow">
          <div className="mr-3" style={{ color: "rgba(255, 255, 255, 0.8)" }}>
            {date()}
          </div>
          <div
            className="truncate"
            style={{ color: "rgba(255, 255, 255, 0.4)", maxWidth: 270 }}
          >
            {messages[0].content}
          </div>
        </div>
        <div className="flex items-center">
          {request.status == "OPEN" && (
            <div
              className="p-1 mr-2"
              style={{
                backgroundColor: "rgba(221, 77, 77, 0.7)",
                color: "rgba(255, 255, 255, 0.8)",
                borderRadius: 3,
              }}
            >
              <svg
                className="w-4 h-4"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M2.003 5.884L10 9.882l7.997-3.998A2 2 0 0016 4H4a2 2 0 00-1.997 1.884z" />
                <path d="M18 8.118l-8 4-8-4V14a2 2 0 002 2h12a2 2 0 002-2V8.118z" />
              </svg>
            </div>
          )}
          <div
            style={{
              color: "rgba(255, 255, 255, 0.5)",
              transform: "rotate(90)",
            }}
          >
            {isOpen ? (
              <svg
                className="w-5 h-5"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  d="M3.293 9.707a1 1 0 010-1.414l6-6a1 1 0 011.414 0l6 6a1 1 0 01-1.414 1.414L11 5.414V17a1 1 0 11-2 0V5.414L4.707 9.707a1 1 0 01-1.414 0z"
                  clipRule="evenodd"
                />
              </svg>
            ) : (
              <svg
                className="w-5 h-5"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M19 14l-7 7m0 0l-7-7m7 7V3"
                />
              </svg>
            )}
          </div>
        </div>
      </div>
      {isOpen && (
        <div className="px-8 py-3">
          {messages.map((msg) => (
            <div
              className="p-3 mb-3"
              style={{ backgroundColor: colors.supportAlt, borderRadius: 6 }}
            >
              <div className="flex mb-2 items-center">
                <img
                  className="rounded-full h-5 w-5 mr-2"
                  src={msg.profile.iconUrl}
                />
                <span
                  style={{ fontSize: 15, color: "rgba(255, 255, 255, 0.4)" }}
                >
                  {msg.profile.nickname}
                </span>
                {msg.staff && (
                  <span
                    className="ml-2 px-1"
                    style={{
                      backgroundColor: "rgba(224, 117, 71, 0.33)",
                      color: "rgba(255, 255, 255, 0.6)",
                      borderRadius: 3,
                      fontSize: 10,
                    }}
                  >
                    STAFF
                  </span>
                )}
              </div>
              <div style={{ color: "rgba(255, 255, 255, 0.5)" }}>
                {msg.content}
              </div>
            </div>
          ))}
          <div
            className="p-3 mb-3 cursor-pointer"
            style={{ backgroundColor: colors.supportAlt, borderRadius: 6 }}
            onClick={closeRequest}
          >
            <div
              className="flex items-center"
              style={{ color: "rgba(255, 255, 255, 0.5)" }}
            >
              <svg
                className="w-6 h-6 mr-2"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  d="M13.477 14.89A6 6 0 015.11 6.524l8.367 8.368zm1.414-1.414L6.524 5.11a6 6 0 018.367 8.367zM18 10a8 8 0 11-16 0 8 8 0 0116 0z"
                  clipRule="evenodd"
                />
              </svg>
              <div>CLOSE REQUEST</div>
            </div>
          </div>
          {request.status == "CLOSED" ? (
            <div
              className="p-3 mb-3"
              style={{ backgroundColor: colors.supportAlt, borderRadius: 6 }}
            >
              <div
                className="flex items-center"
                style={{ color: "rgba(255, 255, 255, 0.5)" }}
              >
                <svg
                  className="w-6 h-6 mr-2"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    d="M13.477 14.89A6 6 0 015.11 6.524l8.367 8.368zm1.414-1.414L6.524 5.11a6 6 0 018.367 8.367zM18 10a8 8 0 11-16 0 8 8 0 0116 0z"
                    clipRule="evenodd"
                  />
                </svg>
                <div>This request has been closed.</div>
              </div>
            </div>
          ) : (
            <div
              style={{ border: "2px solid #2D2F34", borderRadius: 6 }}
              className="mb-3 p-3"
            >
              <textarea
                disabled={isLoading}
                maxLength={500}
                className="w-full"
                style={{
                  background: "none",
                  resize: "none",
                  outline: "none",
                  caretColor: "#fff",
                  fontSize: 15,
                  color: "rgba(255, 255, 255, 0.3)",
                }}
                rows={2}
                value={content}
                onChange={(e) => setContent(e.target.value)}
                placeholder="Type a response here."
              />
              <div className="text-right">
                <button
                  onClick={addReply}
                  className="px-6"
                  disabled={isLoading || content.length === 0}
                  style={{
                    borderRadius: 3,
                    color: "rgba(255, 255, 255, 0.7)",
                    fontSize: 13,
                    backgroundColor: colors.supportAlt,
                  }}
                >
                  {isLoading ? "Loading" : "Reply"}
                </button>
                <button
                  onClick={() => {
                    addReply().then(() => {
                      closeRequest()
                    })
                  }}
                  className="px-6 ml-2"
                  disabled={isLoading || content.length === 0}
                  style={{
                    borderRadius: 3,
                    color: "rgba(255, 255, 255, 0.7)",
                    fontSize: 13,
                    backgroundColor: colors.supportAlt,
                  }}
                >
                  {isLoading ? "Loading" : "Close & Reply"}
                </button>
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
}

export default function TeamSupport() {
  const [requests, setRequests] = useState<TeamRequest[]>([])
  const sortedRequests = requests
    .slice()
    .sort(
      (a, b) =>
        new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
    );
  const openRequests = useMemo(
    () => requests.filter((r) => r.status == "OPEN"),
    [requests]
  );
  const pendingRequests = useMemo(
    () => requests.filter((r) => r.status == "WAITING_FOR_REPLY"),
    [requests]
  );

  useEffect(() => {
    axios.get(`/moderation/requests`).then((data) => {
      const requests = data.data.map(r => buildTeamRequestFromResponse(r))
      setRequests(requests)
    });
  }, []);

  const handleRequestChange = (tr: string) => {
    setRequests(requests.filter(f => f.id !== tr))
  }

  return (
    <div
      className="h-screen w-full overflow-y-auto"
      style={{ background: colors.mainContentBackground }}
    >
      <div className="flex justify-center mt-8">
        <div>
          {sortedRequests.map((req) => (
            <RequestBox request={req} onChange={handleRequestChange} />
          ))}
        </div>
      </div>
    </div>
  );
}
