import React from "react";
import styled from 'styled-components';
import {colors} from '../../../utils/colors';
import Tooltip from '../../components/Tooltip';

interface Props {
  name: string;
  iconUrl?: string;
  active?: boolean;
  children?: React.ReactElement;
}

interface IconProps {
  active: boolean;
}

const StyledEventIcon = styled.div<IconProps>`
  background: ${props => props.active ? colors.orange : colors.mainContentBackground};
  transition: all 0.4s;
  border-radius: 14px;
  cursor: pointer;

  &:hover {
    background: ${colors.orange};
  }
`;

export default function EventsIcon({name, iconUrl, children, active= false}: Props) {
  return (
    <Tooltip>
      <StyledEventIcon active={active} className="w-16 h-16 m-4 flex text-white">
        {children ? (children) : (<img className="m-auto" src={iconUrl} width={40} />)}
      </StyledEventIcon>
      <span>{name}</span>
    </Tooltip>

  )
}
