import {Profile} from "./User";

export interface RequestMessage {
  createdAt: string;
  profile: Profile;
  staff: boolean;
  content: string;
}

export type RequestStatus = 'OPEN' | 'CLOSED' | 'WAITING_FOR_REPLY'

export interface TeamRequest {
  id: string;
  teamId: string;
  status: RequestStatus;
  createdAt: string;
  messages: RequestMessage[]
}

export const buildTeamRequestFromResponse = (data): TeamRequest => ({
  createdAt: data.createdAt,
  id: data.id,
  teamId: data.teamId,
  status: data.status,
  messages: data.messages.map(m => buildRequestMessageFromResponse(m))
})

export const buildRequestMessageFromResponse = (data): RequestMessage => ({
  profile: {
    id: data.profile.id,
    nickname: data.profile.nickname,
    iconUrl: data.profile.iconUrl,
  },
  content: data.content,
  staff: data.staff,
  createdAt: data.createdAt
})