import React, {useState} from "react";
import { useHistory } from "react-router-dom"
import Input from "../Input";
import Button from "../Button";

interface Props {
    urlPrefix: string;
}

export default function EntitySearchBox({urlPrefix}: Props) {
    const history = useHistory()
    const [id, setId] = useState<string>('')

    const onClick = () => {
        history.push(`${urlPrefix}/${id}`)
    }

    return (
        <div className="content-modal p-8" style={{width: 450}}>
                <Input className="px-12" label="EntityId" value={id} onChange={(e) => setId(e.target.value)} />
                <div className="text-center mt-5">
                    <Button text="Find" disabled={!id} onClick={onClick} />
                </div>
        </div>
    )
}