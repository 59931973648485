import { createSelector } from "@reduxjs/toolkit";

import { RootState } from "types";
import { initialState } from "./slice";

const selectDomain = (state: RootState) => state.auth || initialState;

export const selectIsAuthenticated = createSelector(
  [selectDomain],
  (authState) => !!authState.authKey
);

export const selectIsInitialLoadFinished = createSelector([selectDomain], (authState) => authState.initialLoadFinished)
