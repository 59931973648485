import {buildUserFromResponse, User} from '../../../types/User';
import Input from '../Input';
import TextField from '../TextField';
import Button from '../Button';
import React, {useState} from 'react';
import axios from 'axios';

interface Props {
  user: User;
  onChange: (user: User) => void;
}

export default function Ban({user, onChange}: Props) {
  const [reason, setReason] = useState<string>('');
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const handleBan = () => {
    setIsLoading(true)
    axios.post(`moderation/user/${user.id}/ban`, {reason: reason}).then(res => {
      onChange(buildUserFromResponse(res.data))
    }).catch(() => {})
  }

  return (
    <div className="px-12 py-4 mx-auto mt-12 content-modal" style={{width: 475}}>
      {user.banned ? (
        <div className="text-center">
          <div className="mb-2" style={{color: 'rgba(255, 255, 255, 0.7)', fontSize: 18}}>Are you sure you want to unban this user?</div>
          <Button onClick={handleBan} text="Confirm" />
        </div>
      ) : (
        <>
          <div style={{color: 'rgba(255, 255, 255, 0.7)', fontSize: 18}}>Ban user</div>
          <TextField label="Reason" value={reason} onChange={(e) => setReason(e.target.value)} className="mb-2" />
          <Button disabled={reason.length == 0} isLoading={isLoading} text="Ban" onClick={handleBan} />
        </>
      )}
    </div>
  )
}
