import EntitySearchBox from "../EntitySearchBox";
import React from "react";
import {colors} from "../../../utils/colors";

export default function UsersHome() {
    return (
        <div className="h-screen w-full" style={{background: colors.mainContentBackground}}>
            <div className="flex justify-center mt-8">
                <EntitySearchBox urlPrefix="user" />
            </div>
        </div>
    )
}