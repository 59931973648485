import React from 'react';
import {colors} from '../../../utils/colors';
import styled, {keyframes} from 'styled-components';

const pulse = keyframes`
  0% {
    color: rgba(255, 255, 255, 1);
  }

  50% {
    color: rgba(255, 255, 255, 0.4);
  }
  
  100% {
    color: rgba(255, 255, 255, 1);
  }
`;

const World = styled.div`
 animation: ${pulse} 2s linear infinite;
`

export default function LoadingComponent() {
  return (
    <div className="h-screen w-full" style={{background: colors.mainContentBackground}}>
      <div className="mx-auto mt-20 text-center p-10 content-modal" style={{width: 450}}>
        <div className="flex justify-center items-center">
          <span style={{color: 'rgba(255, 255, 255, 0.9)', fontSize: 28}}>RustLounge </span>
          <World className="w-8 h-8 ml-2">
            <svg fill="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" viewBox="0 0 24 24" stroke="currentColor"><path d="M21 12a9 9 0 01-9 9m9-9a9 9 0 00-9-9m9 9H3m9 9a9 9 0 01-9-9m9 9c1.657 0 3-4.03 3-9s-1.343-9-3-9m0 18c-1.657 0-3-4.03-3-9s1.343-9 3-9m-9 9a9 9 0 019-9"/></svg>
          </World>
        </div>
        <div className="my-4 underline" style={{color: 'rgba(255, 255, 255, 0.7)', fontSize: 16}}>
          <a href="https://rustlounge.gg">return to rustlounge.gg</a>
        </div>
      </div>
    </div>
  )
}
