import React from 'react';
import styled, {keyframes} from 'styled-components';
import {colors} from '../../../utils/colors';

interface Props {
  onClick?: () => void;
  text?: string;
  height?: number;
  width?: number;
  className?: string;
  isLoading?: boolean;
  disabled?: boolean;
}

const pulse = keyframes`
  0% {
    color: rgba(255, 255, 255, 1);
  }

  50% {
    color: rgba(255, 255, 255, 0.4);
  }
  
  100% {
    color: rgba(255, 255, 255, 1);
  }
`;

// <svg className="mx-auto" width={30} height={30} fill="currentColor" viewBox="0 0 20 20"><path d="M6 10a2 2 0 11-4 0 2 2 0 014 0zM12 10a2 2 0 11-4 0 2 2 0 014 0zM16 12a2 2 0 100-4 2 2 0 000 4z"></path></svg>

const StyledButton = styled.button<Props>`
  background: ${colors.orange};
  border-radius: 30px;
  font-size: 16px;
  color: #fff;
  text-align: center;
  transition: all 0.3s;
  
  &:hover {
    filter: brightness(80%);
  }
`;

export default function Button({onClick, text, height= 32, width= 135, isLoading, className, disabled}: Props) {
  return (
    <StyledButton className={className} onClick={onClick} disabled={isLoading || disabled} isLoading={isLoading} style={{height, width, filter: isLoading || disabled ? 'brightness(60%)' : undefined}}>
      {!isLoading ? text : ('Loading')}
    </StyledButton>
  )
}
