import React, {useEffect, useState} from 'react';
import {buildUserFromResponse, User} from '../../../types/User';
import {useParams} from 'react-router-dom';
import axios from 'axios';
import {colors} from '../../../utils/colors';
import styled from 'styled-components';
import Profile from './Profile';
import Teams from './Teams';
import Ban from './Ban';
import Warn from "./Warn";

interface SettingsItemTabProps {
  active: boolean;
}

const SettingsItemTab = styled.button<SettingsItemTabProps>`
  background: ${props => props.active ? '#2D2F34' : 'none'};
  font-size: 18px;
  border-radius: 6px;
  transition: all 0.2s;
  color: rgba(255, 255, 255, 0.8);
  
  
  &:hover {
    background: #2D2F34;
  }
  
  &:focus {
    outline: none;
  }
`;

enum Page {
  PROFILE,
  TEAMS,
  BAN,
  WARN,
}

export default function UserView() {
  const [user, setUser] = useState<User | null>(null)
  const [page, setPage] = useState<Page>(Page.PROFILE)
  const {id} = useParams()

  useEffect(() => {
    axios.get(`moderation/user/${id}`).then(res => {
      setUser(buildUserFromResponse(res.data))
    }).catch(() => {})
  }, [id])

  const settingItemTabClass = 'w-full text-left px-3 py-1 my-1';

  return (
    <>
      <div className="h-screen" style={{background: colors.secondaryBackground, width: 300}}>
        <div className="flex py-2 px-3" style={{background: colors.modalBackground, boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.25)'}}>
          <img src={user?.iconUrl} className="rounded-full" width={32} style={{height: 32}} />
          <span className="my-auto ml-2" style={{color: 'rgba(255, 255, 255, 0.8)'}}>{user?.nickname}</span>
        </div>
        <div className="pt-3 px-3">
          <SettingsItemTab className={settingItemTabClass} active={page === Page.PROFILE} onClick={() => setPage(Page.PROFILE)}>
            Profile
          </SettingsItemTab>
          <SettingsItemTab className={settingItemTabClass} active={page === Page.TEAMS} onClick={() => setPage(Page.TEAMS)}>
            Teams
          </SettingsItemTab>
          <SettingsItemTab className={settingItemTabClass} active={page === Page.WARN} style={{color: colors.red}} onClick={() => setPage(Page.WARN)}>
            Warn user
          </SettingsItemTab>
          <SettingsItemTab className={settingItemTabClass} active={false} style={{color: colors.red}} onClick={() => setPage(Page.BAN)}>
            {user?.banned ? 'Unban user' : 'Ban user'}
          </SettingsItemTab>
        </div>
      </div>
      <div className="h-screen w-full" style={{background: colors.mainContentBackground}}>
        {!!user && (
          <>
            {page === Page.PROFILE && (<Profile user={user} />)}
            {page === Page.TEAMS && (<Teams user={user} />)}
            {page === Page.BAN && (<Ban user={user} onChange={(user) => {
              setUser(user)
              setPage(Page.PROFILE)
            }} />)}
            {page === Page.WARN && (<Warn user={user} onChange={(user) => {
              setUser(user)
              setPage(Page.PROFILE)
            }} />)}
          </>
        )}
      </div>
    </>
  )
}
