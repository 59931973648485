import { call, put, takeLatest, takeEvery } from 'redux-saga/effects';
import { actions } from './slice';
import axios from "axios";

export function* fetchAuth() {
  const token = yield call([sessionStorage, 'getItem'], 'do_not_share');
  yield put(actions.authEnd(token ? token : ''))
}

export function* storeAuth(action: {
  payload: string;
  type: string;
}) {
  yield call(storeToken, action.payload)
}

export function* authSaga() {
  yield takeEvery(actions.authStart, fetchAuth);
  yield takeLatest(actions.authEnd.type, storeAuth)
}

async function storeToken(token) {
  axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
  try {
    await sessionStorage.setItem('do_not_share', token);
  } catch (error) {
    console.log('AsyncStorage error during token store:', error);
  }
}
