import {buildProfileFromResponse, Profile} from './User';

export interface Team {
  id: string;
  name: string;
  disabled: boolean;
  leaderId: string;
  invite: string;
  tournament: TournamentPreview;
  members: Profile[];
}

export interface TeamPreview {
  id: string;
  name: string;
}

export interface TournamentPreview {
  id: string;
  name: string;
  iconUrl: string;
}

export const buildTeamFromResponse = (data): Team => ({
  id: data.id,
  name: data.name,
  disabled: data.disabled,
  leaderId: data.leaderId,
  invite: data.invite,
  tournament: buildTournamentPreviewFromResponse(data.tournament),
  members: data.members.map(member => buildProfileFromResponse(member))
})

export const buildTournamentPreviewFromResponse = (data): TournamentPreview => ({
  id: data.id,
  name: data.name,
  iconUrl: data.iconUrl,
})

export const buildTeamPreviewFromResponse = (data): TeamPreview => ({
  id: data.id,
  name: data.name,
})
