/**
 *
 * Auth
 *
 */

import React from "react";

import { useInjectReducer, useInjectSaga } from "utils/redux-injectors";
import { reducer, sliceKey } from "./slice";
import { authSaga } from "./saga";

interface Props {}

export function Auth(props: Props) {
  useInjectReducer({ key: sliceKey, reducer: reducer });
  useInjectSaga({ key: sliceKey, saga: authSaga });

  return (
    <>
    </>
  );
}
