import { PayloadAction } from "@reduxjs/toolkit";
import { createSlice } from "utils/@reduxjs/toolkit";
import { ContainerState } from "./types";

// The initial state of the Auth container
export const initialState: ContainerState = {
  authKey: '',
  initialLoadFinished: false,
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    authStart(state, action: PayloadAction) {},
    authEnd(state, action: PayloadAction<string>) {
      state.authKey = action.payload;
      state.initialLoadFinished = true;
    }
  },
});

export const { actions, reducer, name: sliceKey } = authSlice;
